<template>
  <span>
    <h5>{{ field.label }} Filter</h5>
    <b-form-group>
      <select
        v-model="filterMode"
        class="custom-select"
        @change="updateFilterStatus"
      >
        <option
          value="NONE"
        >
          Kein Filter
        </option>
        <option
          value="VISIBLE"
        >
          Sichtbar
        </option>
        <option
          value="NOT_VISIBLE"
        >
          Nicht sichtbar
        </option>
      </select>
    </b-form-group>
  </span>
</template>

<script>
export default {
  name: 'FilterList',
  props: {
    field: {
      type: Object,
      required: true
    },
    opened: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      filterMode: 'NONE'
    }
  },
  computed: {
    query () {
      const query = { $and: [], $or: [] }
      if (this.filterMode === 'VISIBLE') {
        query.$and.push(
          {
            publishedAt: {
              $lte: new Date()
            }
          },
          {
            publishedAt: {
              $exists: true
            }
          }
        )
      } else if (this.filterMode === 'NOT_VISIBLE') {
        query.$or.push(
          {
            publishedAt: {
              $gt: new Date()
            }
          },
          {
            publishedAt: {
              $exists: false
            }
          }
        )
      }
      if (query.$and.length === 0) delete query.$and
      if (query.$or.length === 0) delete query.$or
      return query
    }
  },
  methods: {
    updateFilterStatus () {
      this.$emit('change', {
        isApplied: (this.filterMode !== 'NONE'),
        query: this.query
      })
    },
    selectOption (key, option) {
      const optionIndex = this.selectedOptions.findIndex(selectedOption => selectedOption.value === option.value)
      if (optionIndex !== -1) {
        this.selectedOptions.splice(optionIndex, 1)
      } else {
        this.selectedOptions.push(option)
      }
    },
    deleteFilter () {
      this.filterMode = 'NONE'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
